body{
    align-items: center
}

.calculator{
    overflow:hidden;
    max-width: 100%;
    max-height: 100%;
    width:20em;
    height:30em;
    margin: auto;
    margin-top:3em;
    display: grid;
    grid-template-rows: 1fr 1fr 2fr;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0, 0.15);
    padding: 1em 1.5em 2em 1.5em;
    text-align:center;
    background: #fafafa
}

input{
    text-align: end;
    width: inherit;
    border: none;
    padding-right: 0.5em;
    font-size:1.1em;
    color:#494b4c; 
    background: #fafafa    
}

input:focus{
    outline:none;
}

.result{
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0px 0px 4px 0px rgba(79, 236, 242, 0.6);
    height: 1.8em;    
    font-size:1.8em;
    padding-right: 0.5em;
    color:rgba(79, 236, 242);
    font-weight:600;
    background: #fff
}

button{
    height: 2em;
    border: none;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(79, 236, 242, 0.6);
    margin:0.3em;
    font-size:1em;
    font-weight:500;
    color:#383c3d; 
}

button:hover{
    background: rgba(79, 236, 242, 0.3)
}

.buttons{
    display: grid;
    grid-template-rows:1fr 1fr 1fr 
}

.buttonsgroup{
    display: grid;
    grid-template-columns:1fr 1fr 1fr 
}