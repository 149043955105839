$white: #fafafa;
$offwhite: #faF9F6;
$font: OpenSans,Arial,sans-serif;
$dark: #56739b;
$header-color: linear-gradient(185deg, rgb(127, 130, 142), rgb(	65, 69, 90));
$footer-color: #30322f;
$shadow: 0px 1px 3px #cccccc;
$vertical-menu-color: rgb(	65, 69, 90);
$background-deco: linear-gradient(rgba(0, 0, 0, 0.35) 2%, rgba(0, 0, 0, 0.8)), url(../../media/bckg2.webp)  center top no-repeat;
$hover-color: rgba(	211, 211, 211, 0.7);
$font-color: #30322f; 

 
.App {
   text-align: center;
	margin:0rem;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
    background-color: $white;
    font-family: $font;
    font-size:1.1em;
    color: $font-color;
    
}

a {
	color: $offwhite;
	text-decoration:none !important;
}
a[title]:after{
    background-color: red
}
a:hover{
	color: $hover-color;
}



@keyframes header {
  to {
    background-image: $header-color;
  }
}

.header {
    background-image: none;
	animation: header 1s infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -10s);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    height: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $offwhite;
    flex-wrap: wrap;
    position: fixed;
    width: 100%;
    justify-content: space-between;
    filter: saturate(1.2) brightness(1) contrast(1.4);
    z-index: 2;
}

.header a, .header .logo{
    padding: 0.5em
}

.bckg * {
    background-color: #57628F;
    filter: none
}
.carousels {
    background-color: #fff;
    min-height: 25rem;
    height: -moz-fit-content;
    position: relative;
    color: $font-color;
    display: flex;
    flex-direction: column;
    overflow:hidden;width: 100%;
}
.certificate{
    width: -webkit-fill-available;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 2em;
    margin-bottom: 2em;
    
}

.carousel{
    align-items: center;
    justify-items:center;
    padding-top:3em;
    padding-bottom:3em;
    width: 100%;
}
.carousel1 {
    background-color: #fafafa;
}
.carousel .card{
    background-color: white
}
.container{
    width: 25em;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    max-width:85%
}

.image{
    border-radius:50%;
    width: 50%
}

.myself {
    padding: 0 !important;
    margin-left: 4rem;
    font-size: 1.4rem !important;
    height: inherit;
    display: flex;
    align-items: center;
}

.profile{
	position:relative;
	float:right;
    margin-right: 4rem
}

.photo_container{
	overflow: hidden;
    border-radius: 0.8rem;
    padding: 0.1rem;
    border: 6px double transparent;
	box-shadow: 2px 4px 12px 2px rgba(0,0,0,0.2);
    margin: 1em;
    max-width: 100%;
}


.headline{
	font-size:1.5rem;
	color: $offwhite;
	text-align:center;
    text-shadow: 2px 2px rgba(0,0,0,1);
    margin-top: 2em;
    margin-bottom: auto;
    
    
}

.page_break{
	border-bottom: 1px solid rgba(255,255,255,0.1);
	padding-bottom: 2rem;
    margin-bottom: 2rem;
    color: rgb(255,255,255,0.8)
}

.first {
    background: $background-deco;
    background-repeat: no-repeat;
    background-size: cover ;
    height: 36rem;
    flex-direction: column;
    filter: brightness(1) saturate(1) hue-rotate(15deg) contrast(1);
    color: white;
    padding-top:7em;
    margin-bottom: 2em
}

.card2, .card {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    border-image-slice: 1;
    height: 25em;
    min-width: 24%;
    margin: 0.5rem;
    background: #fafafa;
    justify-content: center;
    display:flex;
    }
.card2 {
    flex-direction:row;
    align-items: center;
    
}
.row * {
    max-height: 5em;
    max-width: 5em;
    margin: 1em
}
.brand{

    display: grid;
    grid-template-columns:1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;}
.row{
    display: grid;
    justify-items: center;
    grid-template-rows:1fr 1fr 1fr;
    }
ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:start;
    overflow: hidden;
    padding: 0;
}
    
li{
    padding: 1rem 0rem;
}

.listTitle {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2em;
    
}

.flag{
    height: 0.8rem;
    margin-right: 0.5rem
}
.projects{
    display: grid;
    grid-template-columns: 1fr 1fr ;
}
.about {
    position: relative;
    display: block;
    max-width: 35rem;
    padding:0.5em;
    text-align: left
}
.about a{
    color: $dark
}
.screenshot{
    max-height: 9em;
    display: block;
    border-radius: 0.4rem;
    margin-left: auto;
    margin-right: auto;
    min-height: 6em;
}
  

.logo {
    height: 2.2rem;
    opacity: 0.85;
}
.logo2{
    height:6em;
    margin-bottom: 2em
}
.dropdown {
    right: 0.5rem;
    position: relative;
    top: 0.1em;
}

.dropdown:hover {
    opacity: 0.5;
}

.sidemenu {
    position: fixed;
    background: $vertical-menu-color;
    height:-webkit-fill-available;
    width: -webkit-fill-available;
    overflow:hidden;
    z-index: 3;
    font-size: 1.2em
}

.close-icon {
    float:right;
    color: $offwhite;
    opacity: 0.9;
    margin: 1.2rem;
}

.close-icon:hover {
    color: $hover-color;
}

.menu-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    margin-top: 6rem;
    color: $offwhite
}

.menu-item {
    margin:4rem 0rem;
    animation: anime-ease-in 0.5s ease-in;
    margin-left: 0
}

.menu-item:hover{
    color: $hover-color;
}

@keyframes anime-ease-in {
    from {
        margin-left: 4rem;
        opacity: 0.4
    }
    to {
        margin-left: 0;
        opacity: 1
    }
    
}

@keyframes fade {
    from {
        opacity: 0.2
    }
    to {
        opacity: 1
    }
    
}

.icons{
	height:2rem;
	padding:0rem 1rem;
	cursor:pointer;
	filter: contrast(0.5);
}

.icons:hover{
	filter: contrast(0.8);
}

.footer {
    background: $footer-color;
    min-height: 20rem;
    flex-direction: column;
    filter: contrast(0.85);
    display: flex;
    flex-direction: column;
    margin-top: 2em
}

.copywright {
    left: 1em;
    bottom: 1em;
    position:absolute;
    color: $offwhite;
    font-size:0.7em
}

.description {
    bottom: 1em;
    right:1em;
    position:absolute;
    color: $offwhite;
    font-size:1em
}



.photo_container:hover{
   opacity: 0.6

}

.animate__animated {
    display:flex;
    align-items: center;
}
@media screen and (max-width: 700px) {
    .screenshot{
    max-width: 16em
    }
    .header .logo{
    padding:0px
    }
    .myself{
    margin-left:0.3em!important
   }
    .card2{
        
        flex-direction: column
    }
}
@media screen and (max-width: 1100px){
    .myself { 
        margin-left: 2rem
    }
    .carousels, .carousel, .animate__animated, .projects {
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        display: flex;
        flex-direction: column;
        width: 95%;
        justify-items: center;
        overflow: hidden;
        padding:1em
        
    }
    .first, .last, .certificate {
        flex-direction: column;
    }
    .card {
        align-self: center;
        width:30em;
    }
   
    
    .header {
        animation-delay: calc(var(--scroll) * -25s);
    }
    .copywright{
        left: auto
    }
    .photo_container{
    align-self: flex-start;
    margin: 0.5em
}
    
}

.visible {
  top: 0;
  transition: top 0.4s ease-out;
}

.hidden {
  top: -200px!important;
  transition: top 0.4s ease-out;
}
.image404{
  max-width: 100%;
  overflow: hidden;
  padding-top:4em
}

a:hover, Link:hover{
opacity: 0.6
}